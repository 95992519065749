import { createReducer, on } from '@ngrx/store'
import { filter, isEqual, sumBy, uniq, map } from 'lodash-es'
import { createEntityAdapter, EntityState } from '@ngrx/entity'

import * as ReceiptViewActions from './receipt-view.actions'
import { ElasticSearchFilters } from 'src/services/elastic-search'
import { prepareReceiptsPayload } from './receipt-view-filters.utils'
import { ReceiptRow } from 'src/services/data/receipts-elastic.service'

export const receiptViewsFeatureKey = 'receiptViews'

export interface ReceiptViewsState extends EntityState<ReceiptRow> {
  filters: Dictionary<ElasticSearchFilters>
  prevQuery: Dictionary<any>
  // receipt ids lists grouped by table key
  tables: Dictionary<string[]>
  // total receipts lists grouped by table key
  totals: Dictionary<Dictionary<any>>
}

const selectId = row => {
  return row.receipt_id
}

export const adapter = createEntityAdapter<ReceiptRow>({ selectId })

const initialState: ReceiptViewsState = adapter.getInitialState({
  tables: {},
  totals: {},
  filters: {},
  prevQuery: {},
})

export const receiptViewReducer = createReducer(
  initialState,
  on(ReceiptViewActions.loadReceiptViews,
    (state, action) => {
      const key = action.tableKey
      const query = prepareReceiptsPayload(action.filters, action.page)
      const prev = state.prevQuery[key] || {}

      let { tables, totals, prevQuery } = state
      if (!isEqual(query.query, prev.query)) {
        tables = { ...tables, [key]: undefined } // drop table
        prevQuery = { ...prevQuery, [key]: query } // save query
  
      } else if (!isEqual(query.sort, prev.sort)) {
        const table = [...tables[key] || []]
        tables = { ...tables, [key]: table.fill(undefined) } // clear table
        prevQuery = { ...prevQuery, [key]: query } // save query
      }
      return { ...state, tables, totals: {}, prevQuery }
    }),
  on(ReceiptViewActions.loadReceiptViewsSuccess,
    (state, action) => {
      const key = action.tableKey
      const { total, receiptViews, payload: { skip } } = action
      const receiptIds = receiptViews.map(selectId)

      let table = Array.from<string>({ length: total }).fill(undefined)
      table.splice(skip, receiptIds.length, ...receiptIds) // NOTE: receiptIds.length or limit?
      const tables = { ...state.tables, [key]: table }
      let totals = { }
      const currencies = filter(uniq(map(receiptViews, item => item.currency)))
      if (currencies.length == 1) {
        totals = { [key]: {
          currency: currencies[0],
          assigned: sumBy(receiptViews, (item) => item.assigned || 0),
          unapplied: sumBy(receiptViews, (item) => item.unapplied || 0),
          amount: sumBy(receiptViews, (item) => item.amount || 0)
        } }
      }
      state = adapter.upsertMany(action.receiptViews, state)
      return { ...state, tables, totals }
    }),
  on(ReceiptViewActions.loadReceiptFiltersSuccess,
    (state, action) => {
      const key = action.tableKey
      const filters = { ...state.filters, [key]: action.result }
      return { ...state, filters }
    }),
)

